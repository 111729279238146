import React, { useEffect, useState } from "react";
import { POSTS } from "./API";
import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useTranslation } from "react-i18next";
import { ApiBaseUrl } from "../../services/config";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
function Posts(props) {
  const { t, i18n } = useTranslation();
  const [data, setdata] = useState([]);
  const [Title, settitle] = useState("");
  let { post } = useParams();
  let history = useHistory();

  useEffect(() => {
    settitle(post);
    POSTS(post === "Articles")
      .then((res) => {
        setdata(res);
      })
      .catch(() => {});
  }, [post]);

  return (
    <div id={"/post/" + post} style={{ marginTop: "100px" }}>
      <>
        <div
          className="block featureBlock bgGray"
          style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
        >
          <div className="container-fluid">
            <div id="hero" className="heroBlock " className="titleHolder">
              <h2>{t(Title)}</h2>
            </div>
            <Row justify="center" gutter={[16, 16]}>
              {data?.map((ele) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: data.length < 1 ? 12 : 8 }}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        src={ApiBaseUrl + ele?.ImagePath}
                        width="100%"
                        height="100%"
                      />
                    }
                    onClick={() => history.push("/postDetails/" + ele.Id)}
                  >
                    <Meta
                      title={i18n.language === "en" ? ele.TitleEn : ele.Title}
                      description={
                        i18n.language === "en"
                          ? ele.OuterDescriptionEn
                          : ele.OuterDescription
                      }
                      style={{
                        direction: i18n.language === "en" ? "ltr" : "rtl",
                        textAlign: "center",
                      }}
                    />
                    <div
                      style={{ marginTop: "20px" }}
                      className="hover-text-read-more"
                    >
                      {t("Read More")}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </>
    </div>
  );
}

export default Posts;
