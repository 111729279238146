import React, { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";

import {
  DELETE_PRIORITY,
  EDIT_PRIORITIES,
  GET_PRIORITIES,
  GET_PRIORITY_BY_ID,
} from "./Api";
import ModelForm from "./PriorityForm";
import CrudComponent from "../../components/CrudTable/CrudComponent";
import DisplayText from "../../components/DisplayText/DisplayText";
import { data_selector } from "../../store/DataReducer";
import { useSelector } from "react-redux";

const Priorities = () => {
  const { t, i18n } = useTranslation();

  const defaultData = useRef({
    Id: 0,
    ClientId: 0,
    PriorityStatementName: "",
    ReceiveDate: new Date(),
    RequestDate: new Date(),
    Documents: "",
    Note: "",
    UnlocatedDocuments: "",
    Result: "",
  });
  function validation(e) {
    return !e["PriorityStatementName"] || !e["ClientId"];
  }
  const lookups = useSelector(data_selector);
  const columnAttributesRecipes = useMemo(() => {
    return [
      {
        caption: "عميل المكتب",
        field: "ClientId",
        captionEn: "Office client",
        display: "ClientName",
        value: "Id",
        data: lookups.Clients,
        widthRatio: "auto",
      },
      {
        caption: "اسم مامورية الشهر العقاري",
        field: "PriorityStatementName",
        captionEn: "The name of the real estate office",
        widthRatio: "auto",
      },
      {
        caption: "الصادر ضده الطلب",
        field: "RequestAgainst",
        captionEn: "Request Against",
        widthRatio: "auto",
      },
      {
        caption: "تاريخ تقديم الرفع المسرحي",
        field: "ProvidingDate",
        dataType: "date",
        captionEn: "Theatrical Lift Submission Date",
        customizeText: (data) => {
          return (
            <div>
              {data.value ? (
                <DisplayText type={"date"} value={data.value} />
              ) : (
                ""
              )}
            </div>
          );
        },
        widthRatio: "200",
      },

      {
        caption: " تاريخ الإستلام الرفع المسرحي",
        field: "ReceiveDate",
        dataType: "date",
        captionEn: "Theatrical Lift Receipt Date",
        customizeText: (data) => {
          return (
            <div>
              {data.value ? (
                <DisplayText type={"date"} value={data.value} />
              ) : (
                ""
              )}
            </div>
          );
        },
        widthRatio: "200",
      },

      {
        caption: "تاريخ تقديم الطلب",
        field: "RequestDate",
        dataType: "date",
        captionEn: "The date of application",
        customizeText: (data) => {
          return (
            <div>
              {data.value ? (
                <DisplayText type={"date"} value={data.value} />
              ) : (
                ""
              )}
            </div>
          );
        },
        widthRatio: "200",
      },
      {
        caption: "المستندات الموجود",
        field: "Documents",
        captionEn: "Existing documents",
        widthRatio: "auto",
      },
      {
        caption: "المستندات غير الموجود",
        field: "UnlocatedDocuments",
        captionEn: "Documents not found",
        widthRatio: "auto",
      },
      {
        caption: "ما تم بها",
        field: "Result",
        captionEn: "Result",
        widthRatio: "auto",
      },
      {
        caption: "ملحوظة",
        field: "Note",
        widthRatio: "auto",
        captionEn: "Note",
      },
    ];
  }, [lookups.Characteristic, i18n.language, DisplayText]);

  return (
    <div className="content">
      <CrudComponent
        columnAttributes={columnAttributesRecipes}
        defaultValues={defaultData}
        DELETE={DELETE_PRIORITY}
        EDIT={EDIT_PRIORITIES}
        GET={GET_PRIORITIES}
        ElementForm={ModelForm}
        view={!lookups?.Roles?.includes("PrioritiesStatements")}
        title=""
        validation={validation}
      />
    </div>
  );
};

export default Priorities;
