import AboutUs from "../AdminPages/AboutUs/AbourUs";
import ContactUs from "../AdminPages/ContactUs/ContactUs";
import FAQ from "../AdminPages/FAQ";
import Footer from "../AdminPages/Footer";
import Home from "../AdminPages/Home/Home";
import Cases from "../office/Case/Cases";
import Clients from "../office/Clients/Clients";
import Courts from "../office/Courts/Courts";
import Priorities from "../office/Priorities/Priorities";
import Users from "../office/Users/Users";
import ComingSoon from "../office/ComingSoon/ComingSoon";
export const layoutpath = "/dashboard";
export const layout = "/dashboard";
export const routes = [
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home",
    component: Home,
    layout: layout,
    key: ["Admin"],
  },
  {
    path: "/About-us",
    name: "About us",
    icon: "fas fa-user-edit",
    component: AboutUs,
    layout: layout,
    key: ["Admin"],
  },

  {
    path: "/FAQ",
    name: "FAQ",
    icon: "fas fa-solid fa-question",
    component: FAQ,
    layout: layout,
    key: ["Admin"],
  },
  /* {
    path: "/posts",
    name: "Posts",
    icon: "fas fa-newspaper",
    component: Posts,
    layout: layout,
  },*/
  {
    path: "/Contact-us",
    name: "Contact us",
    icon: "far fa-address-book",
    component: ContactUs,
    layout: layout,
    key: ["Admin"],
  },
  {
    path: "/Footer",
    name: "Footer",
    icon: "fas fa-download",
    component: Footer,
    layout: layout,
    key: ["Admin"],
  },
  {
    name: "Coming soon",
    path: "/coming-soon",
    icon: "fas fa-comment-dots",
    component: ComingSoon,
    layout: layout,
    key: ["Cases", "CaseView"],
  },

  {
    name: "Cases",
    path: "/cases",
    icon: "fab fa-buffer",
    component: Cases,
    layout: layout,
    key: ["Cases", "CaseView"],
  },
  {
    name: "PrioritiesStatements",
    path: "/priorities",
    icon: "fas fa-paper-plane",
    key: ["PrioritiesStatements", "PrioritiesStatementsView"],
    component: Priorities,
    layout: layout,
  },

  {
    name: "Courts",
    path: "/courts",
    icon: "fas fa-landmark",
    key: ["Courts"],
    component: Courts,
    layout: layout,
  },

  {
    name: "Clients",
    path: "/clients",
    icon: "fas fa-user-shield",
    key: ["Clients"],
    component: Clients,
    layout: layout,
  },
  {
    name: "Users",
    key: ["Users"],
    path: "/users",
    component: Users,
    icon: "fas fa-user-group",
    layout: layout,
  },
];
