import React from "react";
import { ApiBaseUrl } from "../../../services/config";
import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useTranslation } from "react-i18next";
const AppAbout = ({
  data,
  section,
  details = "lorem ipsum dolor sit amet, consectetur adipiscing elit",
}) => {
  const { t, i18n } = useTranslation();
  const dataz = {
    details:
      "الألم نفسه ، يتم تنفيذ الألم في العملية الدهنية الرئيسية. التدريب لا بد أن ينتقد الأكثر طلبا",
    detailsEn: "lorem ipsum dolor sit amet, consectetur adipiscing elit",
  };
  const body = {
    text: "الألم نفسه ، يتم تنفيذ الألم في العملية الدهنية الرئيسية. التدريب لا بد أن ينتقد الأكثر طلبا",
    textEn:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Exercitationem, tenetur reprehenderit maxime quaerat autem hic neque odio dolorum inventore distinctio asperiores repella",
  };
  const items = [
    {
      key: "1",
      icon: <i className="fas fa-chart-pie"></i>,
      titleEn: "High Performance",
      contentEn:
        "cu nostro dissentias consectetuer mel. Ut admodum conceptam mei, cu eam tation fabulas abhorreant. His ex mandamus.",
      title: "أداء عالي",
      content:
        "عندما تنشر طلبك لأول مرة ، فقد يستغرق الأمر عدة أيام. عادة ما تستغرق الإصدارات اللاحقة عدة ساعات.",
    },
    {
      key: "2",
      icon: <i className="fas fa-desktop"></i>,
      titleEn: "High Performance",
      contentEn:
        "cu nostro dissentias consectetuer mel. Ut admodum conceptam mei, cu eam tation fabulas abhorreant. His ex mandamus.",
      title: "أداء عالي",
      content:
        "عندما تنشر طلبك لأول مرة ، فقد يستغرق الأمر عدة أيام. عادة ما تستغرق الإصدارات اللاحقة عدة ساعات.",
    },
    {
      key: "3",
      icon: <i className="fas fa-database"></i>,
      titleEn: "High Performance",
      contentEn:
        "cu nostro dissentias consectetuer mel. Ut admodum conceptam mei, cu eam tation fabulas abhorreant. His ex mandamus.",
      title: "أداء عالي",
      content:
        "عندما تنشر طلبك لأول مرة ، فقد يستغرق الأمر عدة أيام. عادة ما تستغرق الإصدارات اللاحقة عدة ساعات.",
    },
  ];

  return (
    <>
      <div id="/home#about" className="block aboutBlock ">
        <div className="container-fluid">
          <div className="titleHolder">
            <h2>{t("About us")}</h2>
          </div>
          <div className="contentHolder">
            <p>
              {section
                ? i18n.language === "en"
                  ? section.ContentEn
                  : section.Content
                : null}
            </p>
          </div>
          <Row
            gutter={[16, 16]}
            justify="center"
            className="animate__animated animate__zoomIn"
          >
            {data?.map((item, index) => {
              return (
                <Col md={{ span: 8 }} key={item.key}>
                  <Card
                    //  hoverable={true}
                    cover={
                      <img
                        src={ApiBaseUrl + item?.ImagePath}
                        width="100%"
                        height="100%"
                      />
                    }
                  >
                    <Meta
                      title={i18n.language === "en" ? item.TitleEn : item.Title}
                      description={
                        i18n.language === "en"
                          ? item.DescriptionEn
                          : item.Description
                      }
                      style={{
                        direction: i18n.language === "en" ? "ltr" : "rtl",
                      }}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default React.memo(AppAbout);
