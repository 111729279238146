import { useTranslation } from "react-i18next";
import { DateBox, NumberBox, TextBox } from "../../../components/Inputs/index";

const RecordForm = ({
  values,
  handleChange,
  handleGetImages,
  handleRemoveImage,
  setLoading,
}) => {
  const { t, i18n } = useTranslation();
  // const lookups = useSelector(data_selector);
  // const [cases, setCases] = useState([]);
  // useEffect(() => {
  //   setLoading(true);
  //   GET_CASES()
  //     .then(setCases)
  //     .finally(() => setLoading(false));
  // }, []);
  return (
    <>
      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <SelectBox
          label={t("Case")}
          dataSource={cases}
          keys={{ id: "Id", name: "CircuitAppealNo" }}
          value={values.CaseId}
          name="CaseId"
          requiredInput
          handleChange={handleChange}
        />
      </div> */}
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Bailiffs' pen")}
          value={values["RecordResult"]}
          name="RecordResult"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <NumberBox
          label={t("Advertising No")}
          value={values["AdvertisingNo"]}
          name="AdvertisingNo"
          handleChange={handleChange}
          labelWidth="150px"
          requiredInput
          required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <DateBox
          label={t("Session Date")}
          value={values["SessionDate"]}
          name="SessionDate"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Note")}
          value={values["Note"]}
          name="Note"
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default RecordForm;
