import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CrudTable from "../../../components/CrudTable/CrudTable";
import DisplayText from "../../../components/DisplayText/DisplayText";

import {
  DELETE_RECORD,
  EDIT_RECORD,
  GET_CASE_RECORDS,
  GET_CASE_RECORDS_COMMON,
} from "../Api";
import RecordForm from "./RecordForm";
import { useLocation } from "react-router-dom";
import { data_selector } from "../../../store/DataReducer";
import { useSelector } from "react-redux";
import { DateConvertor, DisplayNumber } from "../../../components/DateFunction";
import { layout } from "../../../layout/Routes";

export default function RecordsTable({ selectedId, setloading, loading }) {
  const { t, i18n } = useTranslation();

  const defaultData = useRef({
    Id: 0,
    CaseId: 0,
    RecordResult: "",
    AdvertisingNo: 0,
    SessionDate: new Date(),
    Note: "",
  });
  // function validation(e) {
  //   return !e["RecordResult"] || !e["AdvertisingNo"];
  // }
  const location = useLocation();
  const columnAttributesRecipes = useMemo(() => {
    return [
      {
        caption: "قلم المحضرين",
        field: "RecordResult",
        captionEn: "Bailiffs' pen",
        widthRatio: "auto",
        customizeTextMain: (data) => {
          return DisplayNumber(data.value, i18n);
        },
      },
      {
        caption: "رقم الإعلان",
        field: "AdvertisingNo",
        captionEn: "Advertising No.",
        widthRatio: "auto",
        customizeTextMain: (data) => {
          return DisplayNumber(data.value, i18n);
        },
      },
      {
        caption: "تاريخ الجلسة",
        field: "SessionDate",
        captionEn: "Session Date",

        customizeTextMain: (data) => {
          return DateConvertor(data.value, i18n);
        },
        customizeText: (data) => {
          return (
            <div>
              {data.value ? (
                <DisplayText type={"date"} value={data.value} />
              ) : (
                ""
              )}
            </div>
          );
        },
        widthRatio: "250",
      },
      {
        caption: "تاريخ الإنشاء",
        field: "Date",
        dataType: "date",
        captionEn: "Created Date",
        customizeTextMain: (data) => {
          return DateConvertor(data.value, i18n);
        },
        customizeText: (data) => {
          return (
            <div>
              {data.value ? (
                <DisplayText type={"date"} value={data.value} />
              ) : (
                ""
              )}
            </div>
          );
        },
        widthRatio: "250",
      },
      {
        caption: "اسم العميل",
        field: "ClientName",
        captionEn: "Client Name",
        customizeTextMain: (data) => {
          return DisplayNumber(data.value, i18n);
        },
        widthRatio: "auto",
      },
      {
        caption: "اسم الخصم",
        field: "OtherSideName",
        captionEn: "Other Side Name",
        customizeTextMain: (data) => {
          return DisplayNumber(data.value, i18n);
        },
        widthRatio: "auto",
      },

      {
        caption: "ملحوظة",
        field: "Note",
        captionEn: "Note",
        customizeTextMain: (data) => {
          return DisplayNumber(data.value, i18n);
        },
        widthRatio: "auto",
      },
    ];
  }, [i18n.language]);
  const lookups = useSelector(data_selector);
  const edit = useCallback((e) => EDIT_RECORD(e, selectedId), [selectedId]);
  const [hereLoading, setLoadingHere] = useState(false);
  return (
    <CrudTable
      columnAttributes={columnAttributesRecipes}
      defaultValues={defaultData}
      DELETE={DELETE_RECORD}
      EDIT={edit}
      GET={
        location.pathname == layout + "/coming-soon"
          ? GET_CASE_RECORDS_COMMON
          : GET_CASE_RECORDS
      }
      ElementForm={RecordForm}
      title="Announcement of bailiffs' papers"
      view={!lookups?.Roles?.includes("Cases")}
      loading={hereLoading}
      setloading={setLoadingHere}
      selectedId={selectedId}
      // validation={validation}
    />
  );
}
