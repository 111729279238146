import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CrudComponent from "../../components/CrudTable/CrudComponent";
import { CaseColumns } from "../../services/SharedData";
import { data_selector } from "../../store/DataReducer";
import { DELETE_CASE, EDIT_CASE, GET_CASES } from "./Api";
import CaseForm from "./CaseForm";
import CaseInfo from "./components/CaseInfo";
const Cases = () => {
  const { t, i18n } = useTranslation();
  const defaultData = useRef({
    Id: 0,
    CircuitAppealNo: 0,
    ClientCharacteristic: 0,
    ClientName: "",
    ClientId: 0,
    OtherSideName: "",
    CourtId: "",
    Subject: "",
    Note: "",
    InvitationNumber: 0,
    Result: "",
    Records: [],
  });
  const lookups = useSelector(data_selector);
  const columnAttributesRecipes = useMemo(
    () => CaseColumns(lookups, i18n),
    [lookups, i18n.language]
  );
  function validation(e) {
    return !e["ClientId"] || !e["CourtId"] || !e["ClientName"];
  }

  return (
    <div className="content">
      <CrudComponent
        columnAttributes={columnAttributesRecipes}
        defaultValues={defaultData}
        DELETE={DELETE_CASE}
        EDIT={EDIT_CASE}
        GET={GET_CASES}
        ElementForm={CaseForm}
        view={!lookups?.Roles?.includes("Cases")}
        title=""
        FooterComponent={CaseInfo}
        validation={validation}
        // MasterRenderDetail={RecordsTable}
      />
    </div>
  );
};

export default Cases;
