import { Card, Col, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import { ApiBaseUrl } from "../../../services/config";
import { useTranslation } from "react-i18next";

function Service({ details, data }) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <>
        <div id="/home#service" className="block featureBlock bgGray">
          <div className="container-fluid">
            <div className="titleHolder">
              <h2>{t("Our Experience")}</h2>
            </div>
            <Row justify="center" gutter={[16, 16]}>
              {data?.map((ele) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: data.length < 1 ? 12 : 8 }}
                >
                  <Card
                    hoverable={true}
                    onClick={() => window.open(ele?.Link, "_blank").focus()}
                    // cover={
                    //   <img
                    //     src={ApiBaseUrl + ele?.ImagePath}
                    //     width="100%"
                    //     height="100%"
                    //   />
                    // }
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Meta
                      title={i18n.language === "en" ? ele.TitleEn : ele.Title}
                      description={
                        i18n.language === "en"
                          ? ele.DescriptionEn
                          : ele.Description
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </>
    </div>
  );
}

export default Service;
