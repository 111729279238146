import { Card, Col, Row } from "antd";
import React from "react";
import Meta from "antd/lib/card/Meta";
import { ApiBaseUrl } from "../../../services/config";
import { useTranslation } from "react-i18next";

const Partners = ({ data }) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="block aboutBlock ">
        <div className="container-fluid">
          <div className="titleHolder">
            <h2>{t("Clients")}</h2>
          </div>
          <Row
            gutter={[16, 16]}
            justify="center"
            className="animate__animated animate__zoomIn"
          >
            {data?.map((item, index) => {
              return (
                <Col
                  xs={{ span: 18 }}
                  sm={{ span: 18 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  key={item.key}
                >
                  <Card
                    hoverable={true}
                    // onClick={() => window.open(item?.Link, "_blank").focus()}
                    // cover={
                    //   <img
                    //     src={
                    //       item.ImagePath
                    //         ? ApiBaseUrl + item?.ImagePath
                    //         : ApiBaseUrl + "/temp.png"
                    //     }
                    //     width="100%"
                    //     height="100%"
                    //   />
                    // }
                  >
                    {item.MemberName && item.MemberName.length > 0 ? (
                      <Meta
                        title={
                          i18n.language === "en"
                            ? item?.MemberNameEn
                            : item?.MemberName
                        }
                        style={{ textAlign: "center" }}
                      />
                    ) : null}
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default React.memo(Partners);
