import React from "react";

import { BackTop } from "antd";
import img2 from "../../assets/law-logo.png";
function AppFooter({ data }) {
  return (
    <div className="container-fluid">
      <div className="footer">
        <div className="logo">
          <img
            src={img2}
            width="100%"
            height={"100%"}
            style={{ width: "70px" }}
          />
        </div>
        <ul className="socials">
          {/* {data?.map((res) => (
            <li>
              <a onClick={() => window.open(res.Link, "_blank").focus()}>
                <i style={{ fontSize: "30px" }} className={res.Type}></i>
              </a>
            </li>
          ))} */}
        </ul>
        <div className="copyright" style={{ width: "100%" }}>
          <b style={{ color: "#dca74a" }}>مكتب خالد عليوة </b> <br />
          للشؤون القانونية &emsp; |&emsp; Emaar <br /> Copyright &copy; 2023
        </div>{" "}
        <BackTop>
          <div className="goTop">
            <i className="fas fa-arrow-circle-up"></i>
          </div>
        </BackTop>
      </div>
    </div>
  );
}

export default AppFooter;
