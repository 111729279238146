import React from "react";

import { useTranslation } from "react-i18next";
import { DateBox, TextBox } from "../../../components/Inputs";

const SessionForm = ({
  values,
  handleChange,
  handleGetImages,
  handleRemoveImage,
  setLoading,
}) => {
  const { t, i18n } = useTranslation();
  // const lookups = useSelector(data_selector);
  // const [cases, setCases] = useState([]);
  // useEffect(() => {
  //   setLoading(true);
  //   GET_CASES()
  //     .then(setCases)
  //     .finally(() => setLoading(false));
  // }, []);
  return (
    <>
      {/* <div className="col-lg-6 col-md-6 col-sm-12">
        <SelectBox
          label={t("Case")}
          dataSource={cases}
          keys={{ id: "Id", name: "CircuitAppealNo" }}
          value={values.CaseId}
          name="CaseId"
          requiredInput
          handleChange={handleChange}
        />
      </div> */}
      <div className="col-lg-6 col-md-6 col-sm-12">
        <DateBox
          label={t("Session Date")}
          value={values["SessionDate"]}
          name="SessionDate"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Result")}
          value={values["Result"]}
          name="Result"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Note")}
          value={values["Note"]}
          name="Note"
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default SessionForm;
