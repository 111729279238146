import { useRef } from "react";

import CrudComponent from "../../components/CrudComponent/CrudComponent";
import {
  INVENTORY,
  INVENTORY_DELETE,
  INVENTORY_INSERT,
  INVENTORY_ORDER,
  INVENTORY_UPDATE,
} from "../AboutUs/AboutUs.Api";

import PartnersCard from "./Components/Partnres/PartnersCard";
import PartnersForm from "./Components/Partnres/PartnersForm";
import {
  HEADER,
  HEADER_DELETE,
  HEADER_INSERT,
  HEADER_ORDER,
  HEADER_UPDATE,
} from "./Home.Api";
import "./Home.css";
import HeadersCard from "./Components/Headers/HeadersCard";
import HeadersForm from "./Components/Headers/HeadersForm";

function Home() {
  const defaultValuesPartners = useRef({
    TitleEn: "",
    Title: "",
    Link: "",
    ImagePath: "",
    Image: "",
    Active: false,
    Rank: 0,
  });
  const defaultValuesHeader = useRef({
    Id: 0,
    Title: "",
    TitleEn: "",
    Description: "",
    DescriptionEn: "",
    ImagePath: "",
    Image: null,
    Active: false,
  });

  return (
    <div className="content">
      <CrudComponent
        GET={HEADER}
        INSERT={HEADER_INSERT}
        UPDATE={HEADER_UPDATE}
        DELETE={HEADER_DELETE}
        ORDER={HEADER_ORDER}
        defaultValues={defaultValuesHeader}
        CardComponent={HeadersCard}
        ElementForm={HeadersForm}
        Key="Id"
        title="Header"
      />
      <CrudComponent
        GET={INVENTORY}
        INSERT={INVENTORY_INSERT}
        UPDATE={INVENTORY_UPDATE}
        DELETE={INVENTORY_DELETE}
        ORDER={INVENTORY_ORDER}
        defaultValues={defaultValuesPartners}
        CardComponent={PartnersCard}
        ElementForm={PartnersForm}
        Key="Id"
        title="Partner"
      />
    </div>
  );
}

export default Home;
