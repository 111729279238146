import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SelectionPopUp from "../../../components/SelectionPopUp/SelectionPopUp";
import { CheckBox } from "../../../components/Inputs";
import { data_selector } from "../../../store/DataReducer";

function SelectStore({ setvalues, setLoading, values, handleChange }) {
  const { t } = useTranslation();
  const columnAttributes = useMemo(() => {
    return [
      {
        caption: "الرقم",
        captionEn: "Number",
        field: "Id",
      },
      {
        caption: "الاسم",
        field: "ClientName",
        captionEn: "Name",
      },
    ];
  }, []);

  // To set the id of the selected row in it to enable deleting or editing on clicking ok.

  const onselection = useCallback(
    (e) => {
      handleChange({
        name: "UserClients",
        value: e,
      });
    },
    [handleChange]
  );

  const lookups = useSelector(data_selector);
  return (
    <div>
      <CheckBox
        label={t("All Clients")}
        value={values["AllClients"] || false}
        name="AllClients"
        handleChange={handleChange}
      />
      {!values["AllClients"] && (
        <SelectionPopUp
          button_title={"Select Client"}
          model_title={"Select Client"}
          setLoading={setLoading}
          values={values.UserClients}
          dataLabel="ClientName"
          onSelectionChange={onselection}
          columnAttributes={columnAttributes}
          icon={"fab fa-bitbucket"}
          data={lookups?.Clients}
          // get_data={GET_SIZES}
        />
      )}
    </div>
  );
}

export default React.memo(SelectStore);
