import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TextBox } from "../../components/Inputs/index";
import { data_selector } from "../../store/DataReducer";
import React, { memo, useCallback, useEffect, useState } from "react";
const ClientForm = ({
  values,
  handleChange,
  handleGetImages,
  handleRemoveImage,
}) => {
  const { t, i18n } = useTranslation();
  const lookups = useSelector(data_selector);

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <TextBox
          label={t("Client Name")}
          value={values["ClientName"]}
          name="ClientName"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
    </>
  );
};

export default ClientForm;
