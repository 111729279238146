import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader/Loader";
import AppHome from "./Components/home";
const { Header } = Layout;

function User({ data, loading }) {
  const { t, i18n } = useTranslation();
  // const [data, setdata] = useState(null);

  return <div>{data && <AppHome data={data} />}</div>;
}

export default User;
