import React, { useMemo, memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DateBox, SelectBox, TextBox } from "../../components/Inputs/index";
import { data_selector } from "../../store/DataReducer";

const PriorityForm = ({
  values,
  handleChange,
  handleGetImages,
  handleRemoveImage,
}) => {
  const { t, i18n } = useTranslation();
  const lookups = useSelector(data_selector);

  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <SelectBox
            label={t("Client")}
            dataSource={lookups.Clients}
            keys={{ id: "Id", name: "ClientName" }}
            value={values.ClientId}
            name="ClientId"
            requiredInput
            handleChange={handleChange}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <TextBox
            label={t("Statement Name")}
            value={values["PriorityStatementName"]}
            name="PriorityStatementName"
            handleChange={handleChange}
            labelWidth="170px"
            requiredInput
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <TextBox
            label={t("Request against")}
            value={values["RequestAgainst"]}
            name="RequestAgainst"
            handleChange={handleChange}
            labelWidth="150px"
            requiredInput
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <DateBox
            label={t("Receive Date")}
            value={values["ReceiveDate"]}
            name="ReceiveDate"
            handleChange={handleChange}
            requiredInput
            required
            labelWidth="160px"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <DateBox
            label={t("Request Date")}
            value={values["RequestDate"]}
            name="RequestDate"
            handleChange={handleChange}
            requiredInput
            required
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <TextBox
            label={t("Documents")}
            value={values["Documents"]}
            name="Documents"
            handleChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <TextBox
            label={t("Unlocated Documents")}
            value={values["UnlocatedDocuments"]}
            name="UnlocatedDocuments"
            labelWidth="190px"
            handleChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <TextBox
            label={t("ResultPer")}
            value={values["Result"]}
            name="Result"
            handleChange={handleChange}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <TextBox
            label={t("Note")}
            value={values["Note"]}
            name="Note"
            handleChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default PriorityForm;
