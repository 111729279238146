import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import CrudComponent from "../../components/CrudComponent/CrudComponent2";
import SizeForm from "./CourtForm";
import { DELETE_COURT, EDIT_COURT, GET_COURT, GET_COURT_BY_ID } from "./Api";
const Courts = () => {
  const { t, i18n } = useTranslation();
  const defaultData = useRef({
    Id: 0,
    CourtName: "",
  });

  const columnAttributes = useMemo(() => {
    return [
      {
        caption: "الرقم",
        captionEn: "Number",
        field: "Id",
      },
      {
        caption: "الاسم",
        field: "CourtName",
        captionEn: "Name",
      },
    ];
  }, []);
  return (
    <div className="content">
      <CrudComponent
        columnAttributes={columnAttributes}
        defaultData={defaultData}
        DELETE={DELETE_COURT}
        EDIT={EDIT_COURT}
        GET={GET_COURT}
        GET_BY_ID={GET_COURT_BY_ID}
        Form={SizeForm}
      />
    </div>
  );
};

export default Courts;
