import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppAbout from "./about";

import AppContact from "./contact";
import AppFaq from "./faq";
import AppHero from "./hero";
import Partners from "./Partners";
import Service from "./Service";
import AppWorks from "./works";
import CEO from "./CEO";

const AppHome = ({ data }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {}, []);

  return (
    <>
      <div
        className="main"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <AppHero data={data.Headers} />
        <AppAbout
          section={
            data.Sections
              ? data?.Sections?.filter((e) => e.SectionName === "AboutUs")[0]
                  ?.Text[0]
              : null
          }
          data={data.Benefits}
        />
        <Service data={data.Service} />
        <CEO data={data.Partners} />

        <AppWorks
          section={
            data.Sections
              ? data?.Sections?.filter((e) => e.SectionName === "Works")[0]
                  ?.Text
              : []
          }
          Video={data?.Social?.filter((e) => e.Type === "Video")[0]?.Link}
        />
        <AppFaq data={data.Questions} />
        <Partners data={data.Member} />
        <AppContact data={data.Branches} />
      </div>
    </>
  );
};

export default React.memo(AppHome);
