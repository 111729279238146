import React, { useMemo, useRef } from "react";

import { useTranslation } from "react-i18next";
import CrudComponent from "../../components/CrudComponent/CrudComponent2";
import {
  DELETE_CLIENT,
  EDIT_CLIENT,
  GET_CLIENTS,
  GET_CLIENT_BY_ID,
} from "./Api";
import ModelForm from "./ClientForm";

const Clients = () => {
  const { t, i18n } = useTranslation();

  const defaultData = useRef({
    Id: 0,
    ClientName: "",
  });
  const columnAttributes = useMemo(() => {
    return [
      {
        caption: "الرقم",
        captionEn: "Number",
        field: "Id",
      },
      {
        caption: "الاسم",
        field: "ClientName",
        captionEn: "Name",
      },
    ];
  }, []);

  return (
    <div className="content">
      <CrudComponent
        columnAttributes={columnAttributes}
        defaultData={defaultData}
        DELETE={DELETE_CLIENT}
        EDIT={EDIT_CLIENT}
        GET={GET_CLIENTS}
        GET_BY_ID={GET_CLIENT_BY_ID}
        Form={ModelForm}
      />
    </div>
  );
};

export default Clients;
