import React from "react";
import { Card, Col, Row } from "antd";

import Meta from "antd/lib/card/Meta";

import { useTranslation } from "react-i18next";
import { ApiBaseUrl } from "../../../services/config";
function CEO({ details, data }) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <>
        <div id="feature" className="block featureBlock bgGray">
          <div className="container-fluid">
            <div className="titleHolder">
              <h2>{t("The Office Members")}</h2>
              <p>{details}</p>
            </div>
            <Row justify="center" gutter={[16, 16]}>
              {data?.map((ele) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: data.length < 1 ? 12 : 8 }}
                >
                  <Card
                    cover={
                      <img
                        src={
                          ele.ImagePath
                            ? ApiBaseUrl + ele?.ImagePath
                            : ApiBaseUrl + "/temp.png"
                        }
                        width="100%"
                        height="100%"
                      />
                    }
                  >
                    <Meta
                      title={i18n.language === "en" ? ele.TitleEn : ele.Title}
                      description={
                        i18n.language === "en" ? ele.NoteEn : ele.Note
                      }
                      style={{
                        direction: i18n.language === "en" ? "ltr" : "rtl",
                        textAlign: "center",
                      }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </>
    </div>
  );
}

export default React.memo(CEO);
