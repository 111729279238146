import REQUEST from "../../services/Request";

export const EDIT_CLIENT = async (data) => {
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Client",
    data,
  });
};

export const DELETE_CLIENT = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Client/" + id,
  });
};
export const GET_CLIENTS = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Client",
  });
};
export const GET_CLIENT_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Client/" + id,
  });
};
