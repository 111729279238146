import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CrudComponent from "../../components/CrudTable/CrudComponent";
import { CaseColumns } from "../../services/SharedData";
import { data_selector } from "../../store/DataReducer";
import RecordsTable from "../Case/records/RecordsTable";
import { COMING } from "./Api";
import CaseInfo from "../Case/components/CaseInfo";
const ComingSoon = () => {
  const { t, i18n } = useTranslation();
  // const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   SESSIONS()
  //     .then(setData)
  //     .finally(() => setLoading(false));
  // }, []);
  const lookups = useSelector(data_selector);
  const columnAttributesRecipes = useMemo(
    () => CaseColumns(lookups, i18n),
    [lookups, i18n.language]
  );
  return (
    <div className="content">
      <CrudComponent
        columnAttributes={columnAttributesRecipes}
        defaultValues={{}}
        GET={COMING}
        view={true}
        FooterComponent={CaseInfo}
      />
    </div>
  );
};

export default ComingSoon;
