import Layout, { Content, Footer, Header } from "antd/lib/layout/layout";
import "devextreme/dist/css/dx.light.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";

import "../App.css";
import Loader from "../components/Loader/Loader";
import ScrollToTop from "../components/ScrollTop";
import AppFooter from "../components/common/footer";
import AppHeader from "../components/common/header";
import { ALL } from "../services/SharedApi/Section.Api";
import User from "../views/User";
import Login from "../views/LogIn/Login";
import Posts from "../views/Posts";
import PostDetails from "../views/PostDetails";
function UserLayout() {
  const [data, setdata] = useState({});

  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  if (i18n.language === "en-US") {
    i18n.init();
    document.documentElement.setAttribute("lang", "en");
    i18n.changeLanguage("en");
  }
  useEffect(() => {
    document.scrollingElement && (document.scrollingElement.scrollTop = 0);
    setLoading(true);
    ALL()
      .then((res) => {
        setdata(res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="App">
      <Layout className="mainLayout">
        <Header>
          <AppHeader />
        </Header>
        <Loader loading={loading} />
        <div style={{ minHeight: "500px" }}>
          <Content>
            <ScrollToTop />
            <Switch>
              <Route
                path="/home"
                exact
                component={(e) => <User {...e} data={data} />}
              />
              <Route path="/log-in" exact component={Login} />

              {/* <Route path="/register" exact component={Register} /> */}
              <Route path="/post/:post" exact component={Posts} />
              <Route path="/postDetails/:id" exact component={PostDetails} />
              <Redirect to="/home" />
            </Switch>
          </Content>
        </div>

        <Footer>
          <AppFooter data={data?.Social?.filter((e) => e.Type !== "Video")} />
        </Footer>
      </Layout>
    </div>
  );
}

export default UserLayout;
