import { Anchor, Button, Carousel, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ApiBaseUrl } from "../../../services/config";
import ElementCard from "../../../components/ElementCard/ElementCard";

const { Link } = Anchor;

const AppHero = ({ data }) => {
  return (
    <>
      <div id="/home#hero" className="heroBlock  ">
        <Carousel autoplay autoplaySpeed={3000} effect="fade">
          {data?.map((item, index) => {
            return <ElementCard {...item} FullWidth />;
          })}
        </Carousel>
      </div>
    </>
  );
};

export default React.memo(AppHero);
