import React from "react";
import { TabPanel } from "devextreme-react";

import { Item } from "devextreme-react/tab-panel";
import { useTranslation } from "react-i18next";
import RecordsTable from "../records/RecordsTable";
import SessionsTable from "../sessions/SessionsTable";
export default function CaseInfo(props) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <TabPanel swipeEnabled={false} animationEnabled={true}>
        <Item
          title={t("Sessions")}
          render={() => (
            <div style={{ padding: 20 }}>
              <SessionsTable {...props} />
            </div>
          )}
        />
        <Item
          title={t("Announcement of bailiffs' papers")}
          render={() => (
            <div style={{ padding: 20 }}>
              <RecordsTable {...props} />
            </div>
          )}
        />
      </TabPanel>
    </div>
  );
}
