import { useTranslation } from "react-i18next";
import React, { useMemo, useRef } from "react";
import { TextBox } from "../../components/Inputs/index";
const CourtForm = ({ values, handleChange }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <TextBox
          label={t("Court Name")}
          value={values["CourtName"]}
          name="CourtName"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
    </>
  );
};

export default CourtForm;
