import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CrudTable from "../../../components/CrudTable/CrudTable";
import DisplayText from "../../../components/DisplayText/DisplayText";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { data_selector } from "../../../store/DataReducer";
import {
  DELETE_SESSION,
  EDIT_SESSION,
  GET_CASE_SESSIONS,
  GET_CASE_SESSIONS_COMMON,
} from "./Api";
import SessionForm from "./SessionForm";
import { DateConvertor, DisplayNumber } from "../../../components/DateFunction";
import { layout } from "../../../layout/Routes";

export default function SessionsTable({ selectedId, setloading, loading }) {
  const { t, i18n } = useTranslation();

  const defaultData = useRef({
    Id: 0,
    CaseId: 0,
    Result: "",
    SessionDate: new Date(),
    Note: "",
  });
  // function validation(e) {
  //   return !e["RecordResult"] || !e["AdvertisingNo"];
  // }
  const location = useLocation();
  const columnAttributesRecipes = useMemo(() => {
    return [
      {
        caption: "اسم العميل",
        field: "ClientName",
        captionEn: "Client Name",
        widthRatio: "auto",
      },
      {
        caption: "اسم الخصم",
        field: "OtherSideName",
        captionEn: "Other Side Name",
        widthRatio: "auto",
      },
      {
        caption: "تاريخ الجلسة",
        field: "SessionDate",
        captionEn: "Session Date",
        customizeTextMain: (data) => {
          return DateConvertor(data.value, i18n);
        },
        // widthRatio: "auto",
        customizeText: (data) => {
          return (
            <div>
              {data.value ? (
                <DisplayText type={"date"} value={data.value} />
              ) : (
                ""
              )}
            </div>
          );
        },
        widthRatio: "250",
      },
      {
        caption: "النتائج",
        field: "Result",
        captionEn: "Result",
        widthRatio: "250",
        customizeTextMain: (data) => {
          return DisplayNumber(data.value, i18n);
        },
      },
      {
        caption: "ملحوظة",
        field: "Note",
        captionEn: "Note",
        widthRatio: "auto",
        customizeTextMain: (data) => {
          return DisplayNumber(data.value, i18n);
        },
      },
    ];
  }, [i18n.language]);
  const lookups = useSelector(data_selector);
  const edit = useCallback((e) => EDIT_SESSION(e, selectedId), [selectedId]);
  const [hereLoading, setLoadingHere] = useState(false);
  return (
    <CrudTable
      columnAttributes={columnAttributesRecipes}
      defaultValues={defaultData}
      DELETE={DELETE_SESSION}
      EDIT={edit}
      GET={
        location.pathname == layout + "/coming-soon"
          ? GET_CASE_SESSIONS_COMMON
          : GET_CASE_SESSIONS
      }
      ElementForm={SessionForm}
      title="Sessions"
      view={!lookups?.Roles?.includes("Cases")}
      loading={hereLoading}
      setloading={setLoadingHere}
      selectedId={selectedId}
      //  validation={validation}
    />
  );
}
