import REQUEST from "../../services/Request";

export const EDIT_PRIORITIES = async (data) => {
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "PrioritiesStatement",
    data,
  });
};

export const DELETE_PRIORITY = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "PrioritiesStatement/" + id,
  });
};
export const GET_PRIORITIES = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "PrioritiesStatement",
  });
};
export const GET_PRIORITY_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "PrioritiesStatement/" + id,
  });
};
