import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  NumberBox,
  SelectBox,
  TextArea,
  TextBox,
} from "../../components/Inputs/index";
import { data_selector } from "../../store/DataReducer";
const CaseForm = ({ values, handleChange }) => {
  const { t, i18n } = useTranslation();
  const lookups = useSelector(data_selector);

  return (
    <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <SelectBox
          label={t("Client")}
          dataSource={lookups.Clients}
          keys={{ id: "Id", name: "ClientName" }}
          value={values.ClientId}
          name="ClientId"
          requiredInput
          handleChange={handleChange}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <SelectBox
          label={t("Court")}
          dataSource={lookups.Courts}
          keys={{ id: "Id", name: "CourtName" }}
          value={values.CourtId}
          name="CourtId"
          requiredInput
          handleChange={handleChange}
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Appeal Number And Circuit")}
          value={values["CircuitAndAppealNumber"]}
          name="CircuitAndAppealNumber"
          handleChange={handleChange}
          // requiredInput
          // required
        />
      </div>

      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Invitation Number And Circuit")}
          value={values["CircuitAndInvitationNumber"]}
          name="CircuitAndInvitationNumber"
          handleChange={handleChange}
          // requiredInput
          // required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Client Name")}
          value={values["ClientName"]}
          name="ClientName"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <SelectBox
          label={t("Characteristic")}
          dataSource={lookups.Characteristic}
          keys={{ id: "Id", name: "Name", nameEn: "NameEn" }}
          value={values.ClientCharacteristic}
          name="ClientCharacteristic"
          requiredInput
          handleChange={handleChange}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextBox
          label={t("Other Side Name")}
          value={values["OtherSideName"]}
          name="OtherSideName"
          handleChange={handleChange}
          requiredInput
          required
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextArea
          label={t("Subject")}
          value={values["Subject"]}
          name="Subject"
          handleChange={handleChange}
          requiredInput
          required
          height={110}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextArea
          label={t("Note")}
          value={values["Note"]}
          name="Note"
          handleChange={handleChange}
          height={110}
        />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <TextArea
          label={t("Sessions")}
          value={values["Result"]}
          name="Result"
          handleChange={handleChange}
          height={110}
        />
      </div>

      {/* {values.Id > 0 && (
        <>
          <h1>{t("Records")}</h1>
          <RecordsTable Records={values.Records} />
        </>
      )} */}
    </div>
  );
};

export default CaseForm;
