import React from "react";

import { Collapse, Button, Descriptions } from "antd";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

function AppFaq({
  data,
  Details = "Quidem reiciendis iure, aperiam blanditiis. Alias esse, nam, ea quam",
}) {
  const { t, i18n } = useTranslation();
  return (
    <div id="/home#faq" className="block faqBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>{t("Frequently Asked Questions")}</h2>
        </div>
        <div style={{ direction: "ltr" }}>
          <Collapse defaultActiveKey={[]}>
            {data?.map((item, index) => (
              <Panel
                header={
                  i18n.language === "en"
                    ? item.QuestionTextEn
                    : item.QuestionText
                }
                key={index}
              >
                <p style={{ direction: "ltr" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "en" ? item.AnswerEn : item.Answer,
                    }}
                  ></div>
                </p>
              </Panel>
            ))}
          </Collapse>
        </div>
        <div className="quickSupport"></div>
      </div>
    </div>
  );
}

export default React.memo(AppFaq);
