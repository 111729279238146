import REQUEST from "../../../services/Request";
export const EDIT_SESSION = async (data, select) => {
  data["CaseId"] = select;
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Session",
    data,
  });
};

export const DELETE_SESSION = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Session/" + id,
  });
};
export const GET_SESSIONS = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Session",
  });
};
export const GET_CASE_SESSIONS = async (type) => {
  return await REQUEST({
    method: "GET",
    url: "Session/case/" + type,
  });
};
export const GET_CASE_SESSIONS_COMMON = async (type) => {
  return await REQUEST({
    method: "GET",
    url: "Session/coming/" + type,
  });
};
