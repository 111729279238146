import REQUEST from "../../services/Request";

export const GET_COURT = async () => {
  return await REQUEST({
    method: "GET",
    url: "Court",
  });
};
export const GET_COURT_ADMIN = async () => {
  return await REQUEST({
    method: "GET",
    url: "Court/admin",
  });
};
export const GET_COURT_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Court/" + id,
  });
};
export const EDIT_COURT = async (data) => {
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Court",
    data,
  });
};

export const DELETE_COURT = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Court/" + id,
  });
};
