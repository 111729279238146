import REQUEST from "../../services/Request";

export const GET_CASES = async () => {
  return await REQUEST({
    method: "GET",
    url: "Case",
  });
};
export const GET_CASE_ADMIN = async () => {
  return await REQUEST({
    method: "GET",
    url: "Case/admin",
  });
};
export const GET_CASE_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Case/" + id,
  });
};
export const EDIT_CASE = async (data) => {
  let formData = new FormData();

  for (let [key, value] of Object.entries(data)) {
    formData.append(key.toString(), value !== null ? value : "");
  }
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Case",
    data: formData,
  });
};

export const DELETE_CASE = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Case/" + id,
  });
};

export const EDIT_RECORD = async (data, select) => {
  data["CaseId"] = select;
  return await REQUEST({
    method: data.Id > 0 ? "PUT" : "POST",
    url: "Record",
    data,
  });
};

export const DELETE_RECORD = async (id) => {
  return await REQUEST({
    method: "DELETE",
    url: "Record/" + id,
  });
};
export const GET_RECORDS = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Record",
  });
};
export const GET_RECORD_BY_ID = async (id) => {
  return await REQUEST({
    method: "GET",
    url: "Record/" + id,
  });
};
export const GET_CASE_RECORDS = async (type) => {
  return await REQUEST({
    method: "GET",
    url: "Record/case/" + type,
  });
};
export const GET_CASE_RECORDS_COMMON = async (type) => {
  return await REQUEST({
    method: "GET",
    url: "Record/coming/" + type,
  });
};
