import "bootstrap/dist/js/bootstrap.bundle";
import "devextreme/dist/css/dx.light.css";
//import "font-awesome/css/font-awesome.min.css";
import "popper.js/dist/popper";
import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";
import AdminAuth from "./layout/AdminAuth";
import { layout } from "./layout/Routes";
import UserLayout from "./layout/UserLayout";
import { useDispatch, useSelector } from "react-redux";
import { GetFromLocalStorage } from "./services/localStorageService";
import {
  auth_loading,
  userLoginLocalStorage,
  user_selector,
} from "./store/AuthReducer";

import axios from "axios";
import { getData } from "./store/DataReducer";
import Loader from "./components/Loader/Loader";
import "devextreme/dist/css/dx.light.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const loading_redux = useSelector(auth_loading);
  useEffect(() => {
    setLoading(loading_redux);
  }, [loading_redux]);
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    const local = GetFromLocalStorage();
    if (local) {
      axios.defaults.headers.Authorization = `bearer ${local.Token}`;
      dispatch(userLoginLocalStorage(local));
    }
  }, []);
  useEffect(() => {
    if (
      localStorage.getItem("i18nextLng") !== "en" &&
      localStorage.getItem("i18nextLng") !== "ar"
    ) {
      i18n.changeLanguage("en");
    }
  }, [i18n]);
  return (
    <div lang={i18n.language === "en" ? "en" : "ar"}>
      <Loader loading={loading} />
      <Switch>
        <Route path={layout} component={AdminAuth} />
        {/* <Redirect to={layout + "/log-in"} /> */}
        <Route path="/" component={UserLayout} />
      </Switch>
    </div>
  );
}

export default App;
